import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import i18n from 'i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
// For Lazy Loading
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import './homepage.css';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import Iframe from 'react-iframe';
import { MsalContext } from "@azure/msal-react";
import $ from 'jquery';
import LoadingOverlay from 'react-loading-overlay';
import { ROUTE_CONSTANTS, LocalApiBaseUrl, LocalApiBaseUrlImage  } from '../Shared/Constant';
import {
  screeNamesForUserTracking,
  logUserTrackingData,
} from '../Shared/commonFunctions';
import defaultofferingImage from '../Images/DefaultClientCard.svg';
import accentureLogo from '../Images/AccentureLogoPurple.svg';
import SynopsLoader from '../Shared/SynopsLoader';
import Client from '../Images/client-0.svg';
import synopsIcon from '../Images/SynOps_Gradient-Icon.jpg';
import axiosInstance from '../Shared/interceptor';
import ClientBoardingPopup from '../ClientSelect/ClientBoardingPopup';

import { store } from '../reducers/configureStore';
import Notification from '../Shared/Notification';
import ClientSelectPopup from './ClientSelectPopup';
import AddMappingForm from '../ConnectorMaster/AddMappingForm';
import ContactUs from "./ContactUs/ContactUs";

const nf = new Intl.NumberFormat();
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.params = new URLSearchParams(window.location.search);
    let isSupportOpen = this.params.get('isSupportOpen') === 'true';
    this.state = {
      TupleKeys: {
        MaxButtons: 0,
        Seconds: 0,
      },
      footerLoading: true,
      isShowSignout: false,
      ishandleapi: false,
      show: false,
      ClientID: 0,
      Nooffering: false,
      OfferingData: [],
      ButtonData: [],
      displayChatBox: false,
      show1: true,
      IsAllRead: true,
      isVisibleNotification: false,
      isAnyNewNotification: false,
      newNotificationCount: 0,
      isenableFeedbackOptions: false,
      isenableFeedbackForm: isSupportOpen,

      notifications: [],
      isFrontDoorImpactDropdownStatus: false,
      isBenefitAreaDropdownStatus: false,
      isBeneficiariesDropdownStatus: false,

      allBenefits: false,
      Benefits: [],
      tempBenefits: [],
      tempallBenefits: false,
      allBeneficiary: false,
      tempallBeneficiary: false,
      tempBeneficiary: [],
      Beneficiary: [],
      allSynopsFD: false,
      tempallSynopsFD: false,
      tempSynopsFrontSection: [],
      SynopsFrontSection: [],
      Checked4: false,
      Checked3: false,
      Checked1: false,
      CheckedFirst: false,
      Checked2: false,

      ShowButton: false,
      FeedbackDetails: {
        CheckedFirst: false,
        Checked1: false,
        Checked2: false,
        Checked3: false,
        Checked4: false,
      },
      FeedbackDescription: '',
      OnfocusTextareacolor: false,
      ShowButtonRating: false,
      onAnonymously: false,
      rating: [],
      ratingArray: [],
      ratingValue: [],
      ShowMessage: false,
      ShowButtonEnhancement: false,
      TitleEnhancement: '',
      DescriptionEnhancement: '',
      FeedBackScroll: false,

      Title: '',
      TitleDescription: '',
      BenefitIds: '',
      BeneficiaryIds: '',
      SynopsFuncSectionIds: '',
      FeedBackType: 'General',
      IsFeedbackSuccess: false,
      ShowNote: false,
      ShowNoteForLoading: false,
      IsLoadingFeedbackSubmit: false,
      FeedbackDescriptionlength: 0,
      remainingcharacter: 0,
      EnhancementDescriptionlength: 0,
      characterleftShow: false,
      addClientStatus: false,
      clientStoriesModalStatus: false,
      showMapping: false,
      ProfileSrc:'',
      offeringsCount: undefined,
      metricValues: [],
      OtherCount: [],
      metricsAreLoading: {
        offeringsCountIsLoading: true,
        metricValuesIsLoading: true,
        OtherCountIsLoading: true,
      }
    };
    this.mesRef = React.createRef();
    this.clientStoriesModalShow = this.clientStoriesModalShow.bind(this);
    this.clientStoriesModalClose = this.clientStoriesModalClose.bind(this);
  }
  static contextType = MsalContext;
  addClientProjectShow = () => {
    this.setState({
      addClientStatus: true,
      clientStoriesModalStatus: false,
    });
  };

  clientStoriesModalShow() {
    this.setState({
      clientStoriesModalStatus: true,
    });
  }

  clientStoriesModalClose() {
    const { getMyClientStories } = this.props;
    this.setState({
      clientStoriesModalStatus: false,
    });
    getMyClientStories();
  }

  isFrontDoorImpactDropdownOverlay = () => {
    this.setState({ isFrontDoorImpactDropdownStatus: false });
  };

  isSynopsFDDropdown = () => {
    let {
      tempallSynopsFD,
      allSynopsFD,
      tempSynopsFrontSection,
      SynopsFrontSection,
    } = this.state;
    tempallSynopsFD = allSynopsFD;

    tempSynopsFrontSection = tempSynopsFrontSection.map((each) => {
      if (SynopsFrontSection.filter((d) => d.ID == each.ID).length > 0) {
        each.isChecked = true;
        return each;
      }
      each.isChecked = false;
      return each;
    });
    if (
      tempSynopsFrontSection.filter((each) => each.isChecked == true).length
      == tempSynopsFrontSection.length
      && tempSynopsFrontSection.length != 0
    ) {
      tempallSynopsFD = true;
    }

    this.setState({
      isFrontDoorImpactDropdownStatus: true,
      tempallSynopsFD,
      tempSynopsFrontSection,
    });
  };

  handleSynopsFD = (index) => {
    let { tempSynopsFrontSection, tempallSynopsFD } = this.state;
    tempallSynopsFD = false;
    tempSynopsFrontSection[index].isChecked = !tempSynopsFrontSection[index]
      .isChecked;
    if (
      tempSynopsFrontSection.filter((each) => each.isChecked == true).length
      == tempSynopsFrontSection.length
    ) {
      tempallSynopsFD = true;
    }
    this.setState({
      tempSynopsFrontSection,
      tempallSynopsFD,
    },
      () => {
        this.onSynopsFDApply();
      });
  };

  handleSynopsFDAll = () => {
    let { tempallSynopsFD, tempSynopsFrontSection } = this.state;
    tempallSynopsFD = !tempallSynopsFD;
    if (tempallSynopsFD) {
      tempSynopsFrontSection = tempSynopsFrontSection.map((each) => ({
        ...each,
        isChecked: true,
      }));
    } else {
      tempSynopsFrontSection = tempSynopsFrontSection.map((each) => ({
        ...each,
        isChecked: false,
      }));
    }
    this.setState({ tempallSynopsFD, tempSynopsFrontSection },
      () => {
        this.onSynopsFDApply();
      });
  };

  onSynopsFDApply = () => {
    let {
      tempallSynopsFD,
      allSynopsFD,
      SynopsFrontSection,
      tempSynopsFrontSection,
    } = this.state;

    allSynopsFD = tempallSynopsFD;
    SynopsFrontSection = [];
    [...tempSynopsFrontSection].map((each) => {
      if (each.isChecked) {
        SynopsFrontSection.push(each);
      }
    });

    this.setState(
      {
        allSynopsFD,
        SynopsFrontSection,
      },
      () => {
        this.validateForm();
      },
    );
  };

  handleBeneficiary = (index) => {
    let { tempBeneficiary, tempallBeneficiary } = this.state;
    tempBeneficiary[index].isChecked = !tempBeneficiary[index].isChecked;
    tempallBeneficiary = false;
    if (
      tempBeneficiary.filter((each) => each.isChecked == true).length
      == tempBeneficiary.length
    ) {
      tempallBeneficiary = true;
    }

    this.setState({
      tempBeneficiary,
      tempallBeneficiary,
    },
      () => {
        this.handleBeneficiaryApply();
      });
  };

  handleBeneficiaryAll = () => {
    let { tempallBeneficiary, tempBeneficiary } = this.state;
    tempallBeneficiary = !tempallBeneficiary;
    if (tempallBeneficiary) {
      tempBeneficiary = tempBeneficiary.map((each) => ({
        ...each,
        isChecked: true,
      }));
    } else {
      tempBeneficiary = tempBeneficiary.map((each) => ({
        ...each,
        isChecked: false,
      }));
    }
    this.setState({
      tempallBeneficiary,
      tempBeneficiary,
    },
      () => {
        this.handleBeneficiaryApply();
      });
  };

  handleBeneficiaryApply = () => {
    let {
      tempBeneficiary,
      tempallBeneficiary,
      allBeneficiary,
      Beneficiary,
    } = this.state;
    allBeneficiary = tempallBeneficiary;
    Beneficiary = [];
    [...tempBeneficiary].map((each) => {
      if (each.isChecked) {
        Beneficiary.push(each);
      }
    });
    this.setState(
      {
        Beneficiary,
        allBeneficiary,
      },
      () => {
        this.validateForm();
      },
    );
  };

  handleBeneficiaryDropDown = () => {
    let {
      tempBeneficiary,
      tempallBeneficiary,
      allBeneficiary,
      Beneficiary,
    } = this.state;
    tempallBeneficiary = allBeneficiary;
    tempBeneficiary = tempBeneficiary.map((each) => {
      if (Beneficiary.filter((d) => d.ID == each.ID).length > 0) {
        each.isChecked = true;
      } else {
        each.isChecked = false;
      }
      return each;
    });
    if (
      tempBeneficiary.filter((each) => each.isChecked == true).length
      == tempBeneficiary.length
      && tempBeneficiary.length != 0
    ) {
      tempallBeneficiary = true;
    }

    this.setState({
      tempallBeneficiary,
      tempBeneficiary,
      isBeneficiariesDropdownStatus: true,
    });
  };

  isBeneficiariesDropdownOverlay = () => {
    this.setState({ isBeneficiariesDropdownStatus: false });
  };

  isBenefitsDropdown = () => {
    let {
      tempallBenefits, allBenefits, Benefits, tempBenefits,
    } = this.state;
    tempallBenefits = allBenefits;
    tempBenefits = tempBenefits.map((each) => {
      if (Benefits.filter((d) => d.ID == each.ID).length > 0) {
        each.isChecked = true;
      } else {
        each.isChecked = false;
      }
      return each;
    });
    if (
      tempBenefits.filter((each) => each.isChecked == true).length
      == tempBenefits.length
      && tempBenefits.length != 0
    ) {
      tempallBenefits = true;
    }

    this.setState({
      isBenefitAreaDropdownStatus: true,
      tempBenefits,
      tempallBenefits,
    });
  };

  handleBenefitsApply = () => {
    let {
      tempallBenefits, tempBenefits, Benefits, allBenefits,
    } = this.state;
    allBenefits = tempallBenefits;
    Benefits = [];
    tempBenefits.map((each) => {
      if (each.isChecked) {
        Benefits.push(each);
      }
    });
    this.setState(
      {
        Benefits,
        allBenefits,
      },
      () => {
        this.validateForm();
      },
    );
  };

  handleAllBenefitsChange = () => {
    let { tempBenefits, tempallBenefits } = this.state;
    tempallBenefits = !tempallBenefits;
    if (tempallBenefits) {
      tempBenefits = tempBenefits.map((each) => ({ ...each, isChecked: true }));
    } else {
      tempBenefits = tempBenefits.map((each) => ({
        ...each,
        isChecked: false,
      }));
    }

    this.setState({
      tempBenefits,
      tempallBenefits,
    },
      () => {
        this.handleBenefitsApply();
      });
  };

  handleBenefitsChange = (index) => {
    let { tempBenefits, tempallBenefits } = this.state;
    tempallBenefits = false;
    tempBenefits[index].isChecked = !tempBenefits[index].isChecked;
    if (
      tempBenefits.filter((each) => each.isChecked == true).length
      == tempBenefits.length
    ) {
      tempallBenefits = true;
    }

    this.setState({
      tempallBenefits,
      tempBenefits,
    },
      () => {
        this.handleBenefitsApply();
      });
  };

  isBenefitAreaDropdownOverlay = () => {
    this.setState({ isBenefitAreaDropdownStatus: false });
  };

  scrollToBottom = () => {
    this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight;
  };

  getbuttonData() {
    // API call
    axiosInstance.get(`${LocalApiBaseUrl}SO/GetAllButtons`).then((response) => {
      this.setState(
        {
          ButtonData: response.data.LandingPageButtonConfigModel.filter(
            (a) => a.IsActive == true,
          ).sort((a, b) => (a.Sequence > b.Sequence ? 1 : -1)),
          TupleKeys: response.data.TupleKeys,
        },
        () => {
          $('#democarousel').carousel({
            interval: this.state.TupleKeys.Seconds * 1000, // 5000
          });
        },
      );
    });
  }

  fetchInitialData = () => {
    const languageid = store.getState().CurrentFilter.languageData.id;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}UserGuide/GetPopupDropdownData`, {
          params: {
            LanguageID: languageid,
            SectionName: 'Feedback',
          },
        })
        .then((response) => {
          let benefits = response.data.Benefits.sort((a, b) => (a.SequenceForFeedback > b.SequenceForFeedback ? 1 : -1));
          benefits = benefits.map((each) => ({ ...each, isChecked: false }));
          let beneficiaries = response.data.Beneficiary.sort((a, b) => (a.SequenceForFeedback > b.SequenceForFeedback ? 1 : -1));
          beneficiaries = beneficiaries.map((each) => ({
            ...each,
            isChecked: false,
          }));
          let synopsFd = response.data.SynopsFrontSection.sort((a, b) => (a.SequenceForFeedback > b.SequenceForFeedback ? 1 : -1));
          synopsFd = synopsFd.map((each) => ({ ...each, isChecked: false }));
          this.setState({
            tempBenefits: benefits,
            tempBeneficiary: beneficiaries,
            tempSynopsFrontSection: synopsFd,
          });
        })
        .catch((error) => {
          reject();
        });
    });
  };

  getOfferingsCount() {
    axiosInstance
      .get(`${LocalApiBaseUrl}Offering/GetOfferingsListDealOnboarding`, {
        params: {
          LanguageID: 1,
          IsDemo: false,
        },
      })
      .then((response) => {
        this.setState({
          offeringsCount: response.data.length,
          metricsAreLoading: {
            ...this.state.metricsAreLoading,
            offeringsCountIsLoading: false,
          }
        });
      })
      .catch((error) => {
        this.setState({
          metricsAreLoading: {
            ...this.state.metricsAreLoading,
            offeringsCountIsLoading: false,
          }
        });
        console.log(error);
      });
  }

  getOfferingsCountAsync() {
    axiosInstance
      .get(`${LocalApiBaseUrl}GetOfferingListAsync`, {
        params: {
          LanguageID: 1,
          IsDemo: false,
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getMetricValue() {
    axiosInstance
      .get(`${LocalApiBaseUrl}so/GetMetricValue`)
      .then((response) => {
        this.setState({
          metricValues: [...response.data],
          metricsAreLoading: {
            ...this.state.metricsAreLoading,
            metricValuesIsLoading: false,
          }
        });
      })
      .catch((error) => {
        this.setState({
          metricsAreLoading: {
            ...this.state.metricsAreLoading,
            metricValuesIsLoading: false,
          }
        });
        console.log(error);
      });
  }

  getCount() {
    const requestData = {
      clientCount: '5',
      offeringNames: [],
    };
    axiosInstance
      .post(`${LocalApiBaseUrl}so/GetAllCount`, requestData)
      .then((response) => {
        this.setState({
          OtherCount: response.data,
          metricsAreLoading: {
            ...this.state.metricsAreLoading,
            OtherCountIsLoading: false,
          }
        });
      })
      .catch((error) => {
        this.setState({
          metricsAreLoading: {
            ...this.state.metricsAreLoading,
            OtherCountIsLoading: false,
          }
        });
        console.log(error);
      });
  }

  GetProfilePic = () => {
    const EnterpriseID = sessionStorage.getItem("EnterpriseID")
    const profilePicToken = sessionStorage.getItem("profilePicToken")
    axiosInstance
      .post(`${LocalApiBaseUrl}User/DownloadPeopleImage`, { enterpriseId: EnterpriseID,profilePicToken:profilePicToken })
      .then((response) => {
        
          const ProfileSrc = `data:image/jpeg;base64,${response.data}`;
          this.setState({ ProfileSrc });
        
      })
      .catch((error) => {
        // trycatchAlertPopup(error);
      });

    // }
  };

  componentDidMount() {
    $('.widget-floating--state-5').css({'visibility': 'visible'});
    this.getbuttonData();
    this.GetLandingPageQuickLinks();
    this.getCount();
    this.getOfferingsCount();
    this.getMetricValue();
    this.getOfferingsCountAsync();
    this.fetchInitialData();
    setTimeout(() => {
      this.setState({ footerLoading: false });
    }, 4250);
    const { notificationData } = this.props;
    this.setState({
      isAnyNewNotification: notificationData.newNotificationsData.hasNewNotification,
      newNotificationCount: notificationData.newNotificationsData.notificationCount,
    });
    $('#genesys-mxg-frame').addClass("z-100");
    $('#genesys-mxg-frame').addClass("shiftLeft");
    this.GetProfilePic();
  }
  componentWillUnmount(){
    $('#genesys-mxg-frame').removeClass("shiftLeft");
  }


  componentDidUpdate(prevProps) {
    $('.client-carousel-root .owl-next').on('click', () => {
      $('.OfferingPopupData').hide();
    });
    $('.client-carousel-root .owl-prev').on('click', () => {
      $('.OfferingPopupData').hide();
    });
    const { notificationData } = this.props;
    if (
      this.state.show
      || this.state.FeedBackScroll
      || this.state.isenableFeedbackOptions
    ) {
      this.mesRef.current.scrollIntoView({ behavior: 'auto' });
    }
    if (prevProps.notificationData !== notificationData) {
      this.setState({
        isAnyNewNotification: notificationData.newNotificationsData.hasNewNotification,
        newNotificationCount: notificationData.newNotificationsData.notificationCount,
      });
    }
  }

  handleUserNavigation = async (client, toPage) => {
    const { languageData } = this.props;
    const lang = languageData.langCode == 'eng' ? 'en' : languageData.langCode;
    const data = {
      PageName: screeNamesForUserTracking.HomePage,
      Client: client,
      Language: lang,
      Navigation1: toPage,
    };
    await logUserTrackingData(data);
    if (toPage === 'BAT') {
      this.handleUserNavBAT();
    }
  };

  handleUserNavigation1 = async (client, toPage) => {
    const { languageData } = this.props;
    const lang = languageData.langCode == 'eng' ? 'en' : languageData.langCode;
    const data = {
      PageName: screeNamesForUserTracking.HomePage,
      Client: client,
      Language: lang,
      Navigation1: toPage,
    };
    await logUserTrackingData(data);
    this.handleUserNAVClient();
  };

  handleTracking = (client, toPage) => {
    const { languageData } = this.props;
    const lang = languageData.langCode == 'eng' ? 'en' : languageData.langCode;
    const data = {
      PageName: screeNamesForUserTracking.HomePage,
      Client: client,
      Language: lang,
      Navigation1: toPage,
    };
    logUserTrackingData(data);
  };

  handleUserNav = async (client, toPage) => {
    const { languageData } = this.props;
    const lang = languageData.langCode == 'eng' ? 'en' : languageData.langCode;
    const data = {
      PageName: screeNamesForUserTracking.HomePage,
      Client: client,
      Language: lang,
      Navigation1: toPage,
    };
    logUserTrackingData(data);
  };

  handleUserNavBAT = () => {
    const { languageData } = this.props;
    const lang = languageData.langCode == 'eng' ? 'en' : languageData.langCode;
    const data = {
      PageName: screeNamesForUserTracking.BAT_LANDING_SCREEN,
      Language: lang,
    };
    logUserTrackingData(data);
  };

  handleUserNAVClient = () => {
    const { languageData } = this.props;
    const lang = languageData.langCode == 'eng' ? 'en' : languageData.langCode;
    const data = {
      PageName: screeNamesForUserTracking.ClientSelect,
      Language: lang,
    };
    logUserTrackingData(data);
  };

  onOfferingOverlay = () => {
    $('.OfferingPopupData').hide();
  };

  onClickOutside = (e, val) => {
    if (this.state.ClientID != val.OrgEntityID) {
      $('.OfferingPopupData').hide();
    }
  };

  onSelectofferingCount = (event, val) => {
    const mouseX = event.pageX;
    const mouseY = event.pageY;
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: {
          OrgEntityID: val.OrgEntityID,
          LanguageID: this.props.languageData.id,
          featureName: 'PulsePage',
        },
      })
      .then((response) => {
        this.setState(
          {
            ClientID: val.OrgEntityID,
            Nooffering: false,
            show: true,
            OfferingData: response.data,
          },
          () => {
            $('.OfferingPopupData').show();
            $('.OfferingPopupData').offset({
              left: mouseX - 320,
              top: mouseY - 230,
            });
          },
        );
      });
  };

  onSelectHandleOffering = (val) => {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: {
          OrgEntityID: val.OrgEntityID,
          LanguageID: this.props.languageData.id,
          featureName: 'PulsePage',
        },
      })
      .then((response) => {

      });
  };

  onSelectHandle = (e, val) => {
    e.stopPropagation();
    this.setState({ ishandleapi: true });
    this.props.saveCommentHistoryId({
      SOCategoryID: '',
      MetricDataID: '',
      showHistory: false,
    });

    const clientData = {
      name: val.OrgEntityName,
      id: val.OrgEntityID,
      abbr: val.OrgEntityName.substr(0, 2),
      imageName: val.OrgEntityImage,
    };
    this.props.saveClientData(clientData);

    sessionStorage.setItem('clientSelection', JSON.stringify(clientData));

    this.handleUserNav(val.OrgEntityName_En, 'ClientSelect');

    this.continueHandle(clientData, val);
  };

  getSnapshot = (clientData) => {
    const {
      history,
    } = this.props;
    const isPulsePageAccessAllowed = this.checkIfPulsePageAccessAllowed();
    const client = clientData.id;
    const loadDefaultSnapshot = false;
    return new Promise((resolve, reject) => {
      if (client !== undefined && client > 0 && client !== '') {
        axiosInstance
          .get(`${LocalApiBaseUrl}Snapshot/GetSnapshotsByUserID`, {
            params: { OrgEntityID: client, LanguageID: this.props.languageData.id },
          })
          .then((response) => {
            store.dispatch({
              type: 'SNAPSHOT',
              payload: response.data,
            });
            if (response.data != null && response.data.length > 0) {
              const defaultSS = response.data.filter((f) => f.IsDefault === true);
              if (defaultSS.length > 0) {
                store.dispatch({
                  type: 'SYNC_CURRENTFILTER',
                  payload: {
                    OfferingId: defaultSS[0].OfferingID,
                    ProcessId: defaultSS[0].OfferingID,
                    LocationId: defaultSS[0].LocationID,
                    Month: '',
                    LocationOMID: defaultSS[0].PageName === 'Journey Initiatives' ? ['All'] : [],
                    IsDataPublishForLocation: false,
                    isOMIDAvailable: false,
                    OfferingName: defaultSS[0].Offering_En,
                  },
                });
                if (defaultSS[0].PageName !== 'Pulse') {
                  this.props.history.push({
                    pathname: '/Dashboard',
                    state: {
                      Tab: defaultSS[0].PageName,
                      ClientID: client,
                      pulseMenu: false,
                    },
                  });
                  resolve();
                  // onTabChange(defaultSS[0].PageName)
                } else if (isPulsePageAccessAllowed) {
                  this.props.history.push({
                    pathname: '/landing',
                    state: { pulseMenu: true },
                  });
                } else {
                  this.props.history.push({
                    pathname: '/Dashboard',
                    state: { pulseMenu: false },
                  });
                }
              } else if (isPulsePageAccessAllowed) {
                this.props.history.push({
                  pathname: '/landing',
                  state: { pulseMenu: true },
                });
              } else {
                this.props.history.push({
                  pathname: '/Dashboard',
                  state: { pulseMenu: false },
                });
              }
            } else if (isPulsePageAccessAllowed) {
              this.props.history.push({
                pathname: '/landing',
                state: { pulseMenu: true },
              });
            } else {
              this.props.history.push({
                pathname: '/Dashboard',
                state: { pulseMenu: false },
              });
            }
            resolve();
          })
          .catch((err) => {
            if (isPulsePageAccessAllowed) {
              this.props.history.push({
                pathname: '/landing',
                state: { pulseMenu: true },
              });
            } else {
              this.props.history.push({
                pathname: '/Dashboard',
                state: { pulseMenu: false },
              });
            }
            reject();
          });
      } else {
        reject();
      }
    });
  };

  continueHandle = async (clientData, val) => {
    this.props.selectedFilter(clientData);

    const isPulsePageAccessAllowed = this.checkIfPulsePageAccessAllowed();

    await this.getSnapshot(clientData);
    // IF PULSE PAGE ACCESS IS NOT ALLOWED THEN REDIRECT TO "SynOps Applied Now" (Dashboard)
    // if (!isPulsePageAccessAllowed) {
    //   this.props.history.push({
    //     pathname: "/Dashboard",
    //     state: { pulseMenu: false },
    //   });
    // } else {
    //   this.props.history.push({
    //     pathname: "/landing",
    //     state: { pulseMenu: true },
    //   });

    // }
  };

  chatBox = (val, e) => {
    e.preventDefault();
    if (val === 'open') {
      this.setState({ displayChatBox: true });
    } else {
      this.setState({ displayChatBox: false });
    }
  };

  // QUICK LINK API CALL //
  GetLandingPageQuickLinks() {
    // API call
    axiosInstance
      .get(`${LocalApiBaseUrl}SO/GetLandingPageQuickLinks`)
      .then((response) => {
        this.setState({
          rowData: response.data.QuickLinks.filter(
            (a) => a.IsActive == true,
          ).sort((a, b) => (a.Sequence > b.Sequence ? 1 : -1)),

          TupleKeys: response.data.QuickLinksConfigKeys,
        });
      });
  }

  handleCallbackNotificationHide = () => {
    this.setState({
      isVisibleNotification: false,
    });
  };

  showNotification = () => {
    this.setState({ isVisibleNotification: true, IsAllRead: true, });

  };

  handleShow = () => {
    this.setState({
      isVisibleNotification: true,
    });
  };

  checkIfPulsePageAccessAllowed = () => {
    const { roleData } = this.props;
    const foundPermissionObj = _.find(roleData, { FeatureName: 'PulsePage' });

    let hasAccessPermission = false;
    if (!_.isEmpty(foundPermissionObj)) {
      const allowedOperations = foundPermissionObj.Operations;

      // ACCESS WOULD ONLY BE ALLOWED IF THE USER HAS EITHER "VIEW" OR "EDIT" PERMISSION
      if (
        allowedOperations.indexOf('View')
        || allowedOperations.indexOf('Edit')
      ) {
        hasAccessPermission = true;
      }
    }

    return hasAccessPermission;
  };

  // FeedBack Form HAandle change //
  enableFeedbackOptions = (e) => {
    this.setState({
      isenableFeedbackOptions: true,
    });
  };

  disableFeedbackOptions = (e) => {
    this.setState({
      isenableFeedbackOptions: false,
      FeedBackScroll: true,
      IsFeedbackSuccess: false,
      FeedbackDescription: '',
      remainingcharacter: 0,
      onAnonymously: false,
      ratingArray: [],
      CheckedFirst: false,
      Checked1: false,
      Checked2: false,
      Checked3: false,
      Checked4: false,
      characterleftShow: false,
    });
  };

  ScroolOption = (e) => {
    this.setState({
      FeedBackScroll: true,
    });
  };

  clickHere = () => {
    this.setState({
      ShowMessage: false,
      FeedbackDescription: '',
      remainingcharacter: 0,
      onAnonymously: false,
      ratingArray: [],
      CheckedFirst: false,
      Checked1: false,
      Checked2: false,
      Checked3: false,
      Checked4: false,
      ShowButton: false,
      ShowButtonRating: false,
      ShowButtonEnhancement: false,
    });
  };

  enableFeedbackForm = () => {
    this.setState({
      isenableFeedbackForm: true,
      isenableFeedbackOptions:true,
      IsFeedbackSuccess: false,
      FeedBackScroll: true,
      FeedbackDescription: '',
      onAnonymously: false,
      ratingArray: [],
      CheckedFirst: false,
      Checked1: false,
      Checked2: false,
      Checked3: false,
      Checked4: false,
    });
  };

  setFeedbackform = () => {
    this.setState({
      IsFeedbackSuccess: false,
      ShowMessage: false,
    });
  };

  disableFeedbackFormModal = () => {
    this.setState({
      remainingcharacter: 0,
      characterleftShow: false,
      isenableFeedbackForm: false,
    });
  };

  // Feed Back Handle change //
  setFeedback = (e) => {
    let ratingArray = [];
    const selected = [];
    if (e.target.checked) {
      const ratingValue = e.target.value;
      for (let i = 0; i < ratingValue.length; i++) {
        selected.push(ratingValue[i]);
      }
      ratingArray = selected;
      this.setState(
        {
          CheckedFirst: true,
          Checked1: false,
          Checked2: false,
          Checked3: false,
          Checked4: false,
          ratingArray,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState({
        CheckedFirst: !!this.state.Checked1,
        ShowButtonRating: false,
        ratingArray: 0,
        Checked1: false,
        Checked2: false,
        Checked3: false,
        Checked4: false,
      });
    }
  };

  setFeedbackFirst = (e) => {
    let ratingArray;
    const selected = [];
    if (e.target.checked) {
      const ratingValue1 = e.target.value;
      for (let i = 0; i < ratingValue1.length; i++) {
        selected.push(ratingValue1[i]);
      }
      ratingArray = selected;
      this.setState(
        {
          Checked1: true,
          CheckedFirst: true,
          Checked2: false,
          Checked3: false,
          Checked4: false,
          ratingArray,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState({
        CheckedFirst: true,

        ratingArray: 0,
        Checked1: !!this.state.Checked2,
        Checked2: false,
        Checked3: false,
        Checked4: false,
      });
    }
  };

  setFeedbackSecond = (e) => {
    let ratingArray;
    const selected = [];
    if (e.target.checked) {
      const ratingValue2 = e.target.value;
      for (let i = 0; i < ratingValue2.length; i++) {
        selected.push(ratingValue2[i]);
      }
      ratingArray = selected;
      this.setState(
        {
          Checked2: true,
          ratingArray,
          Checked1: true,
          CheckedFirst: true,
          Checked3: false,
          Checked4: false,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState({
        Checked1: true,
        CheckedFirst: true,

        ratingArray: 0,
        Checked2: !!this.state.Checked3,
        Checked3: false,
        Checked4: false,
      });
    }
  };

  setFeedbackThird = (e) => {
    let ratingArray;
    const selected = [];
    if (e.target.checked) {
      const ratingValue3 = e.target.value;
      for (let i = 0; i < ratingValue3.length; i++) {
        selected.push(ratingValue3[i]);
      }
      ratingArray = selected;

      this.setState(
        {
          Checked4: false,
          Checked3: true,
          ratingArray,
          Checked1: true,
          CheckedFirst: true,
          Checked2: true,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState({

        ratingArray: 0,
        CheckedFirst: true,
        Checked1: true,
        Checked2: true,
        Checked3: !!this.state.Checked4,
        Checked4: false,
      });
    }
  };

  setFeedbackFourth = (e) => {
    let ratingArray;
    const selected = [];
    if (e.target.checked) {
      const ratingValue4 = e.target.value;
      for (let i = 0; i < ratingValue4.length; i++) {
        selected.push(ratingValue4[i]);
      }
      ratingArray = selected;
      this.setState(
        {
          Checked4: true,
          ratingArray,
          Checked1: true,
          CheckedFirst: true,
          Checked2: true,
          Checked3: true,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState({
        Checked4: false,
        Checked1: true,
        CheckedFirst: true,
        Checked2: true,
        Checked3: true,

        ratingArray: 0,
      });
    }
  };

  bindTextarea = (e) => {
    const FeedbackDescription = e.target.value;
    const FeedbackDescriptionlength = e.target.value.length;
    if (FeedbackDescriptionlength > 0) {
      const remainingcharacter = 2500 - FeedbackDescriptionlength;
      this.setState({
        FeedbackDescriptionlength,
        remainingcharacter,
        characterleftShow: true,
      });
    } else {
      this.setState({
        remainingcharacter: 0,
        characterleftShow: false,
        FeedbackDescriptionlength: 0,
      });
    }
    this.setState(
      {
        FeedbackDescription,
      },
      () => {
        this.validateForm();
      },
    );
  };

  // FOR COLOR
  OnFocusTextarea = (e) => {
    this.setState({
      OnfocusTextareacolor: true,
    });
  };

  onUtsideClick = (e) => {
    this.setState({
      OnfocusTextareacolor: false,
    });
  };

  onAnonymously = (e) => {
    if (e.target.checked) {
      this.setState({
        onAnonymously: true,
      });
    } else {
      this.setState({
        onAnonymously: false,
      });
    }
  };

  // ENHANCEMENT//
  handlechangeEnhancement = (e) => {
    const TitleEnhancement = e.target.value;
    if (TitleEnhancement != '') {
      this.setState({
        TitleEnhancement,
        ShowButtonEnhancement: true,
      });
    } else {
      this.setState({
        ShowButtonEnhancement: false,
        TitleEnhancement: '',
      });
    }
  };

  handlechangeEnhancementDescription = (e) => {
    const DescriptionEnhancement = e.target.value;
    if (DescriptionEnhancement != '') {
      this.setState({
        DescriptionEnhancement,
        ShowButtonEnhancement: true,
      });
    } else {
      this.setState({
        ShowButtonEnhancement: false,
        DescriptionEnhancement: '',
      });
    }
  };

  // Validation //
  validateForm() {
    if (
      (this.state.CheckedFirst && /\S/.test(this.state.FeedbackDescription))
      || (/\S/.test(this.state.Title)
        && /\S/.test(this.state.TitleDescription)
        && this.state.Beneficiary.length != 0
        && this.state.Benefits.length != 0
        && this.state.SynopsFrontSection.length != 0)
    ) {
      this.setState({ ShowButton: true, ShowButtonRating: true });
      return true;
    }
    this.setState({ ShowButton: false, ShowButtonRating: false });
    return false;
  }

  // Handle save //
  handleSave = () => {
    const ratingArrayFinal = this.state.ratingArray;

    if (this.validateForm()) {
      const {
        Title,
        TitleDescription,
        Benefits,
        Beneficiary,
        SynopsFrontSection,
        FeedBackType,
      } = this.state;
      const languageid = store.getState().CurrentFilter.languageData.id;
      this.setState({
        ShowButton: true,
        IsLoadingFeedbackSubmit: true,
        ShowNoteForLoading: false,
      });

      const requestData = {
        FeedbackType: FeedBackType,
        LanguageID: languageid,
        Title,
        TitleDescription,
        BenefitIds: Benefits.map((each) => each.ID).toString(),
        BeneficiaryIds: Beneficiary.map((each) => each.ID).toString(),
        SynopsFuncSectionIds: SynopsFrontSection.map(
          (each) => each.ID,
        ).toString(),
        Rating: parseInt(ratingArrayFinal),
        Feedback: this.state.FeedbackDescription,
        isAnonymous: this.state.onAnonymously,
      };
      axiosInstance
        .post(`${LocalApiBaseUrl}UserGuide/SubmitFeedbackForm`, requestData)
        .then((response) => {
          // window.alert(response.data.StatusMessage)
          if (response.data.IsSuccess) {
            this.setState({
              IsFeedbackSuccess: true,
              ShowMessage: true,
              IsLoadingFeedbackSubmit: false,
              ShowNote: false,
            });
          } else {
            window.alert(response.data.StatusMessage);
            this.setState({
              ShowButton: false,
              IsLoadingFeedbackSubmit: false,
              ShowNoteForLoading: false,
            });
          }
        })
        .catch((error) => {
          window.alert(error);
          this.setState({
            ShowButton: false,
            ShowButtonRating: false,
            IsLoadingFeedbackSubmit: false,
          });
        });
    }
  };

  handleChange = (name, value) => {
    if (name == 'TitleDescription') {
      const EnhancementDescriptionlength = value.length;
      if (EnhancementDescriptionlength > 0) {
        const remainingcharacter = 1000 - EnhancementDescriptionlength;
        this.setState({
          EnhancementDescriptionlength,
          remainingcharacter,
          characterleftShow: true,
        });
      } else {
        this.setState({
          remainingcharacter: 0,
          characterleftShow: false,
        });
      }
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateForm();
      },
    );
  };

  handleFeedBackType = (type) => {
    if (type == 'General') {
      this.setState({
        ShowNote: false,
        ShowButtonRating: false,
      });
    } else if (type == 'ENH' || type == 'NEW') {
      this.setState({
        ShowNote: true,
      });
    } else if (type == 'NEW') {
      this.setState({
        Title: '',
        TitleDescription: '',
        remainingcharacter: 0,
      });
    }
    this.setState({
      FeedBackType: type,
      isFrontDoorImpactDropdownStatus: false,
      isBenefitAreaDropdownStatus: false,
      isBeneficiariesDropdownStatus: false,

      allBenefits: false,
      Benefits: [],
      tempallBenefits: false,
      allBeneficiary: false,
      tempallBeneficiary: false,
      Beneficiary: [],
      allSynopsFD: false,
      tempallSynopsFD: false,
      SynopsFrontSection: [],

      ShowButton: false,
      FeedbackDetails: {
        CheckedFirst: false,
        Checked1: false,
        Checked2: false,
        Checked3: false,
        Checked4: false,
      },
      FeedbackDescription: '',
      remainingcharacter: 0,
      characterleftShow: false,
      Title: '',
      TitleDescription: '',
      BenefitIds: '',
      BeneficiaryIds: '',
      SynopsFuncSectionIds: '',
      IsFeedbackSuccess: false,

      isenableFeedbackForm: true,
      ShowMessage: false,

      ShowMessage: false,

      onAnonymously: false,
      ratingArray: [],
      CheckedFirst: false,
      Checked1: false,
      Checked2: false,
      Checked3: false,
      Checked4: false,
    });
  };

  handleImageError = (e, status) => {
    if (status === 'Client') {
      e.target.src = Client;
    } else {
      e.target.src = defaultofferingImage;
    }
  };

  childToParent = (action, details) => {
    if (action === 'AddMapping') {
      const dealInfo = {
        OrgEntityID: details.OrgEntityID,
        OrgEntityName: details.OrgEntityName,
        OfferingId: details.OfferingID,
        Offering: details.Offering,
        SubOfferingId: 0,
        SubOffering: details.Process,
        LocationId: details.LocationID,
        DeliveryCenter: details.City,
      };

      this.setState({
        dealInfo,
        showMapping: true,
        addClientStatus: false,
      });
    }
    if (action === 'addProject' || action === 'cancel') {
      this.setState({ addClientStatus: false });
    }
  };

  childToParentForMapping = (selectedTab, draftInfo) => {
    if (selectedTab === 'AddMapping') { this.setState({ showMapping: false, addClientStatus: false }); }
  };

  editAndPublishAccess = () => {
    const status = this.props.roleData.some(
      (data) => data.FeatureName == 'Configurator'
        && data.Operations.some((d) => ['Submit', 'Publish'].includes(d)),
    );
    return status;
  };
  logOut = () => {
    const{userLoginData} =this.props;
    console.log("USER DATA PROPS:",userLoginData);
     const instance=this.context.instance;
     console.log("Instances:",instance);
     instance.logoutRedirect({
       account: instance.getAccountByHomeId(userLoginData.account.homeAccountId)
   });
   };
   openLink = (link) => {
    const a = document.createElement("a");
    a.href = link;
    a.target = "_blank";
    a.click();
  };
  
  formatNumber = (value) => {
    const suffixes = {
        'Bn': 1e9,
        'Mn': 1e6,
        'k': 1e3,
    }
    for (const suffix in suffixes) {
        const divisor = suffixes[suffix];
        if (value >= divisor) {
            return Math.round(value / divisor) + suffix;
        }
    }
  
    return value;
  }

  render() {
    const {
      allSynopsFD,
      tempallSynopsFD,
      SynopsFrontSection,
      tempallBeneficiary,
      tempBeneficiary,
      Beneficiary,
      allBeneficiary,
      tempallBenefits,
      tempBenefits,
      Benefits,
      allBenefits,
      IsFeedbackSuccess,
      showMapping,
      newNotificationCount,
      isVisibleNotification,
      footerLoading,
      isAnyNewNotification,
      CheckedFirst,
      checked1,
      checked2,
      checked3,
      checked4,
      TitleEnhancement,
      addClientStatus
    } = this.state;

    const events = {
      onChange: () => {
        this.onOfferingOverlay();
      },
    };
    const customCarousel = {
      margin: 6,
      dots: false,

      autoplay: false,
      loop: false,
      nav: true,
      responsiveClass: true,
      slideBy: 2,
      responsive: {
        0: {
          items: 2,
        },
        400: {
          items: 2,
        },
        600: {
          items: 2,
        },
        700: {
          items: 2,
        },
        1000: {
          items: 2,
        },
        1200: {
          items: 2,
        },
        1400: {
          items: 2,
        },
        1600: {
          items: 2,
        },
        1900: {
          items: 2,
        },
      },
    };
    const {
      BatCount,
      userclientCount,
      isBatApiLoading,
      UserOnboardedClients,
      OperatingGropus,
      isClientStoriesApiloading,
    } = this.props;

    const clients = this.state.OtherCount.SynopsLand
      ? this.state.OtherCount.SynopsLand.filter(
        (a) => a.LabelKey === 'NoOfClients' && a.IsActive,
      )[0]
      : [];
    const journeys = this.state.OtherCount.SynopsLand
      ? this.state.OtherCount.SynopsLand.filter(
        (a) => a.LabelKey === 'NoOfJourneys' && a.IsActive,
      )[0]
      : [];
    const Offerings = this.state.OtherCount.SynopsLand
      ? this.state.OtherCount.SynopsLand.filter(
        (a) => a.LabelKey === 'NoOfOfferings' && a.IsActive,
      )[0]
      : [];

    return (
      <>
        <LoadingOverlay
          active={
            isBatApiLoading
            || this.state.ishandleapi
            || this.state.IsLoadingFeedbackSubmit || isClientStoriesApiloading
            || this.state.metricsAreLoading.offeringsCountIsLoading
            || this.state.metricsAreLoading.metricValuesIsLoading
            || this.state.metricsAreLoading.OtherCountIsLoading
          }
          // spinner
          spinner={<SynopsLoader />}
        >
          <div className="landing-container">
            <div className="left-section">
              <div className="main-content">
                <div className="aboutSynops">
                  <img src={synopsIcon} alt="synopsIcon" />
                  <div>
                    <h2>SynOps</h2>

                    <p>
                      A platform that enables digital, data-driven, intelligent
                      operations
                    </p>
                  </div>
                </div>
                <div className="titleInfo">
                  <div className="blackText">POWERING</div>
                  <div className="blackText">
                    INTELLIGENT
                    {' '}
                    <br />
                    OPERATIONS
                  </div>

                  <div className="synops-carousel">
                    <div
                      id="democarousel"
                      className="carousel slide landingpage-slider"
                      data-ride="carousel"
                    >
                      <ol className="carousel-indicators">
                        {this.state.ButtonData
                          && this.state.ButtonData.length > 1
                          ? this.state.ButtonData.map((item, index) => (
                            <li
                              data-target="#democarousel"
                              data-slide-to={index.toString()}
                              className={`${index === 0 ? 'active' : ''}`}
                            />
                          ))
                          : ''}

                      </ol>
                      <div className="carousel-inner">
                        {this.state.ButtonData
                          ? this.state.ButtonData.map((item, index) => (
                            <div
                              className={`${index === 0
                                  ? 'carousel-item active'
                                  : 'carousel-item'
                                }`}
                            >

                              {item.Type.toUpperCase() === 'EXTERNAL' ? (
                                <a
                                  href={
                                    item.URL.startsWith('www')
                                      ? `https://${item.URL}`
                                      : item.URL
                                  }
                                  target={
                                    item.Type
                                      && item.Type.toUpperCase() === 'EXTERNAL'
                                      ? '_blank'
                                      : ''
                                  }
                                >
                                  <span className="demoLink">
                                    {item.Name}
                                    <i className="fal fa-angle-right" />
                                  </span>
                                </a>
                              ) : (
                                <NavLink
                                  to={item.URL.replace(
                                    LocalApiBaseUrl.replace(
                                      '/api/',
                                      '',
                                    ).replace('api', ''),
                                    '',
                                  ).toString()}
                                  onClick={(e) => {
                                    this.handleUserNavigation(
                                      null,
                                      item.URL.replace(
                                        LocalApiBaseUrl.replace(
                                          '/api/',
                                          '',
                                        ).replace('api', ''),
                                        '',
                                      ).toString(),
                                    );
                                  }}
                                >

                                  <span className="demoLink">
                                    {item.Name}
                                    <i className="fal fa-angle-right" />
                                  </span>
                                </NavLink>
                              )}
                            </div>
                          ))
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clientJourneyOfferings">
                  <ul>
                    <li>
                      <span className="topText">
                        {clients.LabelValue ? clients.LabelValue : 0}
                      </span>
                      <span className="bottomText">
                        {clients.LabelName
                          ? clients.LabelName.toUpperCase()
                          : 'CLIENTS'}
                      </span>
                    </li>
                    <li>
                      <span className="topText">
                        {journeys.LabelValue ? journeys.LabelValue : 0}
                      </span>
                      <span className="bottomText">
                        {journeys.LabelName
                          ? journeys.LabelName.toUpperCase()
                          : 'JOURNEYS'}
                      </span>
                    </li>
                    <li>
                      <span className="topText">
                        {this.state.offeringsCount ? this.state.offeringsCount : 0}
                      </span>
                      <span className="bottomText">
                        {Offerings.LabelName
                          ? Offerings.LabelName.toUpperCase()
                          : 'OFFERINGS'}
                      </span>
                    </li>
                  </ul>
                  {/* {this.state.displayChatBox ? (
                    <>
                      <div
                        className="chatbot-overlay"
                        onClick={this.chatBox.bind(this, 'close')}
                      >
                        <div className="chatbot-iframe">
                          <p
                            className="chat-box-close-alignment"
                            onClick={this.chatBox.bind(this, 'close')}
                          />
                          <button className="chat-bot-close" />

                          <Iframe
                            url="https://synopsbot.accenture.com/SynOps_ConversationalUI/"

                            width="480px"
                            height="550px"
                            id="myId"
                            left="10px"
                            top="100px"
                            className="chat-box-alignment"
                            display="initial"
                            position="fixed"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )} */}
                </div>
              </div>
              {/* Footer added */}

              <div className="footer-links">
                {/* Feedback Form starts here  */}
                <div className="feedback-form-main">
                  <div className="feedback-form-active">
                      <span
                          className="feedback-item"
                          onClick={this.enableFeedbackForm}
                        >
                          <span className="feedback-form-icon">
                            <span onClick={this.setFeedbackform}></span>
                          </span>
                        </span>
                      </div>
                  </div>
                {/* Feedback Form ends here  */}
                <div className="vip-links">
                  <img src={accentureLogo} alt="accentureLogo" />
                  <ul>
                    {this.state.rowData
                      ? this.state.rowData.map((item, index) => (
                        <>
                          <li>

                            {item.Type.toUpperCase() === 'EXTERNAL' ? (
                              <a
                                href={
                                  item.URL.startsWith('www')
                                    ? `https://${item.URL}`
                                    : item.URL
                                }
                                target={
                                  item.Type
                                    && item.Type.toUpperCase() === 'EXTERNAL'
                                    ? '_blank'
                                    : ''
                                }
                              >
                                {item.Name}
                              </a>
                            ) : (
                              <NavLink
                                to={item.URL.replace(
                                  LocalApiBaseUrl.replace(
                                    '/api/',
                                    '',
                                  ).replace('api', ''),
                                  '',
                                ).toString()}
                                onClick={(e) => {
                                  this.handleUserNavigation(
                                    null,
                                    item.URL.replace(
                                      LocalApiBaseUrl.replace(
                                        '/api/',
                                        '',
                                      ).replace('api', ''),
                                      '',
                                    ).toString(),
                                  );
                                }}
                              >

                                {item.Name}
                              </NavLink>
                            )}
                          </li>

                        </>
                      ))
                      : ''}
                  </ul>
                </div>
              </div>
              {/* )} */}
              {/* Footer end */}
            </div>
            {/* BELL ICON */}
            <div className="right-section">

              <div className="right-section-content">
                <div className="login-profile">
                  <div className="profileInfo">
                    <span>
                      <li className="nav-item assessment-notifylink mr-3">
                        <a
                          href="javascript:void(0)"
                          className='notification-link homepage-notificationlink'
                          onClick={() => this.showNotification()}
                        >
                          <span className="active-notification homepage-notification">
                            {isAnyNewNotification && <span className="notification-count">{newNotificationCount > 9 ? '9+' : newNotificationCount}</span>}
                            <i className="fal fa-bell" />
                          </span>
                        </a>
                      </li>
                    </span>
                    {this.state.ProfileSrc.length>0 ? <img src={this.state.ProfileSrc} />:<i className="fas fa-user-circle" />}
                    {' '}
                    <span className="name">
                      <DropdownButton
                        menuAlign="right"
                        id="signout-button"
                        title={sessionStorage.getItem('EnterpriseID')}
                        className="custom-headerdropdown"
                      >
                        <Dropdown.Item href="javascript:void(0);" onClick={this.logOut}> <i className="fa fa-sign-out" /> {i18n.t('Sign_Out')}</Dropdown.Item>
                      </DropdownButton>
                    </span>
                  </div>
                </div>
                <div className="list-group-data">
                  {/* <a
                    href="javascript:void(0);"
                  >
                    <h2>
                      CLIENT VALUE
                      {' '}
                      <span> </span>
                    </h2>
                  </a> */}
                  <h2
                    style={{ cursor: "default" }}
                  >
                    CLIENT VALUE
                    {' '}
                    {/* <span> </span> */}
                  </h2>
                  <ul>
                    {this.state.metricValues
                          ? this.state.metricValues.filter((a) => a.id === 1 || a.id === 2 || a.id === 3).map((a) => (
                            <li>
                                <span className="topText">{a.kpI_NAME}</span>
                                <span className="bottomText">
                                  {a.kpI_Values 
                                    ? (a.id === 1 || a.id === 2
                                        ? `$${this.formatNumber(a.kpI_Values)}` 
                                        : this.formatNumber(a.kpI_Values))
                                    : 0}
                                </span>
                            </li>
                          ))
                      : ""}
                  </ul>
                  <ul className='client-value-display client-value'>
                    {this.state.metricValues
                      ? this.state.metricValues.filter((a) => a.id === 4 || a.id === 6).map(a => (
                        <li>
                            <span className="topText">{a.kpI_NAME}</span>
                            <span className="bottomText">
                              {a.kpI_Values 
                                ? this.formatNumber(a.kpI_Values)
                                : 0}
                            </span>
                        </li>
                      )) : ""}
                  </ul>
                  <ul className='client-value-display client-value'>
                    {this.state.metricValues
                      ? this.state.metricValues.filter((a) => a.id === 5 || a.id === 7).map(a => (
                        <li>
                            <span className="topText">{a.kpI_NAME}</span>
                            <span className="bottomText">
                              {a.kpI_Values 
                                ? this.formatNumber(a.kpI_Values)
                                : 0}
                            </span>
                        </li>
                      )) : ""}
                  </ul>
                </div>
                <div className="list-group-data benchmark-analytics">
                  <NavLink className="hyperlink"
                    to={`/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`}
                    onClick={(e) => {
                      this.handleUserNavigation(null, 'BAT');
                    }}
                  >
                    <h2>
                      BENCHMARK ANALYTICS
                      {' '}
                      <span> </span>
                    </h2>
                  </NavLink>
                  <ul className='client-value-display'>
                    <li>
                      <span className="topText">Clients</span>
                      <span className="bottomText">
                        {BatCount.clientCount ? BatCount.clientCount : 0}
                      </span>
                    </li>
                    <li>
                      <span className="topText">Internal Metrics</span>
                      <span className="bottomText">
                        {BatCount.internalMetricsCount
                          ? BatCount.internalMetricsCount
                          : 0}
                      </span>
                    </li>
                    <li>
                      <span className="topText">Industry Benchmarks</span>
                      <span className="bottomText">
                        {BatCount.externalBenchMarkCount
                          ? BatCount.externalBenchMarkCount
                          : 0}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="my-client-carousel">
                {UserOnboardedClients.length !== 0 ? (
                  <div className="mySuccessStories">
                    <div className="myclient-stories">
                      <h2 className="seeall-sec">
                        <span>
                          MY CLIENT STORIES(
                          {userclientCount}
                          )
                        </span>
                        {userclientCount > 4 && (
                          <NavLink to="#">
                            <span
                              className="seeAll"
                              onClick={this.clientStoriesModalShow}
                            >
                              SEE ALL
                              {' '}
                            </span>
                          </NavLink>
                        )}
                      </h2>

                      <Dropdown className="custom-headerdropdown">
                        <Dropdown.Toggle id="dropdown-basic" />
                        <Dropdown.Menu menuAlign="right">
                          <NavLink
                            className="addClient"
                            to="#"
                            onClick={this.addClientProjectShow}
                          >
                            {i18n.t('Add_Client_Profile')}
                          </NavLink>
                          {(this.props.isProfileExist || this.props.userRole.IsAdmin) && (
                            <NavLink
                              className="viewClient"
                              to="/viewclientproject"
                            >
                              {i18n.t('View_Client_Profile')}
                            </NavLink>
                          )}
                          {this.editAndPublishAccess()
                            && (this.props.isProfileExist || this.props.userRole.IsAdmin) && (
                              <NavLink
                                className="gotoConfig"
                                to="/admin/Configurator"
                              >
                                {i18n.t('Go_to_Configurator')}
                              </NavLink>
                            )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div
                      className="client-carousel-root"
                      ref={this.mesRef}
                      onMouseLeave={(e) => this.onOfferingOverlay()}
                    >
                      <OwlCarousel
                        {...customCarousel}
                      >
                        {UserOnboardedClients.map((a, index) => (
                          <div
                            className="item"
                            key={index}
                            onMouseEnter={(e) => this.onClickOutside(e, a)}
                          >
                            <LazyLoadComponent>
                              <div
                                className="clientName-Sec"
                                onClick={(e) => this.onSelectHandle(e, a)}
                              >
                                <img
                                  loading="lazy"
                                  src={`${LocalApiBaseUrlImage}${a.OrgEntityImage}`}
                                  alt=""
                                  onError={(e) => {
                                    this.handleImageError(e, 'Client');
                                  }}
                                />
                                <h4>{a.OrgEntityName}</h4>
                              </div>
                            </LazyLoadComponent>
                            <div className="clientOffering-Sec">
                              <span
                                className="offeringVal"
                              >
                                Offerings:
                                {' '}
                                <span
                                  className="offeringCount"
                                  onClick={(e) => this.onSelectofferingCount(e, a)}
                                >
                                  {a.Offeringcount}
                                </span>
                              </span>
                            </div>

                          </div>
                        ))}
                      </OwlCarousel>
                      <div className="OfferingPopupData">
                        <div className="wrapper">
                          <div className="offering-tooltip">
                            <div className="offeringtooltip-heading">
                              <h6>Business</h6>
                            </div>
                            <div className="offeringtooltip-body">
                              <ul>
                                {this.state.OfferingData.map((b, index) => (
                                  <li>
                                    <img
                                      src={`${LocalApiBaseUrlImage}${b.ChildOfferings[0].OfferingImage}`}
                                      alt=""
                                      width="20"
                                      height="20"
                                      loading="lazy"
                                      onError={(e) => {
                                        this.handleImageError(e, 'Offering');
                                      }}
                                    />
                                    <div>
                                      <p>{b.OfferingName}</p>
                                      <span>
                                        <b>{b.ChildOfferings.length}</b>
                                        {' '}
                                        Sub-Offerings
                                      </span>
                                    </div>
                                  </li>
                                ))}

                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mySuccessStories">
                    <div className="myclient-stories">
                      <h2 className="seeall-sec">
                        <span>
                          MY CLIENT STORIES(
                          {userclientCount}
                          )
                        </span>
                      </h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isVisibleNotification ? (
            <Notification
              show={isVisibleNotification}
              onHide={() => this.handleCallbackNotificationHide()}
            />
          ) : null}
          {this.state.isenableFeedbackForm && <ContactUs
                  closeContactSupportModal={this.disableFeedbackFormModal}
                  open={this.state.isenableFeedbackForm}
                  defaultTab={this.params.get("tab")}
                />}
          {/* {this.state.isenableFeedbackForm ? (
            <Modal
              className="feedbackFormModal"
              backdrop="static"
              show={this.state.isenableFeedbackOptions}
              onHide={this.disableFeedbackFormModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Let us know your valuable feedback</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {!IsFeedbackSuccess && !this.state.ShowMessage ? (
                  <div className="feedback-form-wrapper">
                    <p>What type of feedback would you like to provide?</p>
                    <div className="tabs-container">
                      <ul
                        className="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link general active"
                            id="pills-general-tab"
                            data-toggle="pill"
                            href="#pills-general"
                            role="tab"
                            aria-controls="pills-general"
                            aria-selected="true"
                            onClick={() => this.handleFeedBackType('General')}
                          >
                            General
                          </a>
                          <span>
                            Share your feedback on your experience with SynOps
                            Frontdoor
                          </span>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link enhancement"
                            id="pills-enhancement-tab"
                            data-toggle="pill"
                            href="#pills-enhancement"
                            role="tab"
                            aria-controls="pills-enhancement"
                            aria-selected="false"
                            onClick={() => this.handleFeedBackType('ENH')}
                          >
                            Enhancement
                          </a>
                          <span>
                            Share your feedback on enhancing existing features
                            on SynOps Frontdoor
                          </span>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link newidea"
                            id="pills-enhancement-tab"
                            data-toggle="pill"
                            href="#pills-enhancement"
                            role="tab"
                            aria-controls="pills-enhancement"
                            aria-selected="false"
                            onClick={() => this.handleFeedBackType('NEW')}
                          >
                            New Idea
                          </a>
                          <span>
                            Share your feedback on bringing in new features on
                            SynOps Frontdoor
                          </span>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-general"
                          role="tabpanel"
                          aria-labelledby="pills-general-tab"
                        >
                          <h2>
                            Rate your Experience with SynOps
                            {' '}
                            <sup className="sup-rating">*</sup>
                          </h2>
                          <div className="rating-star form-group">
                            <label>
                              <input
                                id="FeedbackOne"
                                type="checkbox"
                                name="foo1"
                                value="1"
                                style={{
                                  width: '13px',
                                  height: '13px',
                                  padding: '0',
                                  margin: '0',
                                  position: 'relative',
                                  top: '-1px',
                                  display: 'none',
                                }}
                                checked={this.state.CheckedFirst}
                                onClick={(e) => this.setFeedback(e)}

                              />
                              <span
                                id="FeedbackTwo"
                                className={
                                  this.state.CheckedFirst ? 'checked' : ''
                                }
                              />
                            </label>
                            <label>
                              <input
                                id="FeedbackThree"
                                type="checkbox"
                                name="foo2"
                                value="2"

                                style={{
                                  width: '13px',
                                  height: '13px',
                                  padding: '0',
                                  margin: '0',
                                  position: 'relative',
                                  top: '-1px',
                                  display: 'none',
                                }}
                                checked={this.state.Checked1}
                                onClick={(e) => this.setFeedbackFirst(e)}

                              />
                              <span
                                className={this.state.Checked1 ? 'checked' : ''}
                              />
                            </label>
                            <label>
                              <input
                                id="FeedbackFour"
                                type="checkbox"
                                name="foo3"
                                value="3"
                                style={{
                                  width: '13px',
                                  height: '13px',
                                  padding: '0',
                                  margin: '0',
                                  position: 'relative',
                                  top: '-1px',
                                  display: 'none',
                                }}
                                checked={this.state.Checked2}
                                onClick={(e) => this.setFeedbackSecond(e)}

                              />
                              <span
                                className={this.state.Checked2 ? 'checked' : ''}
                              />
                            </label>
                            <label>
                              <input
                                id="FeedbackFive"
                                type="checkbox"
                                name="foo4"
                                value="4"
                                style={{
                                  width: '13px',
                                  height: '13px',
                                  padding: '0',
                                  margin: '0',
                                  position: 'relative',
                                  top: '-1px',
                                  display: 'none',
                                }}
                                checked={this.state.Checked3}
                                onClick={(e) => this.setFeedbackThird(e)}

                              />
                              <span
                                className={this.state.Checked3 ? 'checked' : ''}
                              />
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="foo5"
                                value="5"
                                style={{
                                  width: '13px',
                                  height: '13px',
                                  padding: '0',
                                  margin: '0',
                                  position: 'relative',
                                  top: '-1px',
                                  display: 'none',
                                }}
                                checked={this.state.Checked4}
                                onClick={(e) => this.setFeedbackFourth(e)}

                              />
                              <span
                                className={this.state.Checked4 ? 'checked' : ''}
                              />
                            </label>

                          </div>

                          <div className="form-section">
                            <div
                              className={
                                this.state.OnfocusTextareacolor
                                  ? 'form-group border-group onFocusColor'
                                  : 'form-group border-group'
                              }
                            >
                              <label htmlFor="comment" className="form-label">
                                Please provide suggestions or feedback here
                                {' '}
                                <sup>*</sup>
                              </label>
                              <textarea
                                rows="3"
                                name="text"
                                maxLength="2500"
                                className="form-control"
                                value={this.state.FeedbackDescription}
                                onChange={(e) => this.bindTextarea(e)}
                                onFocus={(e) => this.OnFocusTextarea(e)}
                                onBlur={(e) => this.onUtsideClick(e)}
                              />
                            </div>
                            {this.state.characterleftShow ? (
                              <label
                                htmlFor="comment"
                                className="form-label character-form-label"
                              >
                                Character left
                                {' '}
                                {this.state.remainingcharacter}
                              </label>
                            ) : (
                              ''
                            )}
                          </div>

                          <div>
                            <label className="form-check checkbox-checked">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={(e) => this.onAnonymously(e)}
                                name="check-boxes"
                              />
                              <span className="checkmark" />
                              <span className="form-check-label">
                                Click here to provide feedback anonymously
                              </span>
                            </label>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-enhancement"
                          role="tabpanel"
                          aria-labelledby="pills-enhancement-tab"
                        >
                          <div className="enchancement-form">
                            <div className="form-section">
                              <div className="form-group border-group">
                                <label htmlFor="name" className="form-label">
                                  Title
                                  {' '}
                                  <sup>*</sup>
                                </label>
                                <input
                                  maxLength="250"
                                  type="text"
                                  className="form-control"
                                  name="Title"
                                  value={this.state.Title}
                                  onChange={(e) => this.handleChange(
                                    e.target.name,
                                    e.target.value,
                                  )}
                                />
                              </div>
                            </div>
                            <div className="form-section">
                              <div className="form-group border-group">
                                <label htmlFor="description" className="form-label">
                                  Description
                                  {' '}
                                  <sup>*</sup>
                                </label>
                                <textarea
                                  rows="3"
                                  maxLength="1000"
                                  className="form-control"
                                  name="TitleDescription"
                                  value={this.state.TitleDescription}
                                  onChange={(e) => this.handleChange(
                                    e.target.name,
                                    e.target.value,
                                  )}
                                />
                              </div>
                              {this.state.characterleftShow ? (
                                <label
                                  htmlFor="comment"
                                  className="form-label character-form-label"
                                >
                                  Character left
                                  {' '}
                                  {this.state.remainingcharacter}
                                </label>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-section">
                                  <div className="form-group border-group">
                                    <label
                                      htmlFor="offering"
                                      className="form-label"
                                    >
                                      SynOps Frontdoor Section Impacted
                                      {' '}
                                      <sup>*</sup>
                                    </label>
                                    <div
                                      className="custom-dropdown-search"
                                      onClick={this.isSynopsFDDropdown}
                                    >
                                      <div className="custom-dropdownname">
                                        {allSynopsFD == true ? (
                                          <span>{i18n.t('All_Selected')}</span>
                                        ) : SynopsFrontSection.length != 0 ? (
                                          SynopsFrontSection.length <= 2 ? (
                                            <span>
                                              {SynopsFrontSection.map(
                                                (each, ind) => each.Name,
                                              ).join(', ')}
                                              {' '}
                                            </span>
                                          ) : (
                                            <span>
                                              {SynopsFrontSection.map(
                                                (each, ind) => {
                                                  if (ind == 0 || ind == 1) {
                                                    return (
                                                      <span>
                                                        {each.Name.length > 15
                                                          ? `${each.Name.slice(
                                                            0,
                                                            each.Name.length
                                                            - 10,
                                                          )}...`
                                                          : each.Name}
                                                        ,
                                                        {' '}
                                                      </span>
                                                    );
                                                  } if (
                                                    SynopsFrontSection.length
                                                    > 2
                                                    && ind
                                                    === SynopsFrontSection.length
                                                    - 1
                                                  ) {
                                                    return (
                                                      <span>
                                                        +
                                                        {ind - 1}
                                                      </span>
                                                    );
                                                  }
                                                },
                                              )}
                                              {' '}
                                            </span>
                                          )
                                        ) : (
                                          <span>
                                            {i18n.t(
                                              'SynOps Frontdoor Section Impacted',
                                            )}
                                            {' '}
                                          </span>
                                        )}
                                        {' '}
                                      </div>
                                    </div>
                                    {this.state
                                      .isFrontDoorImpactDropdownStatus ? (
                                      <div className="featuresearch-list">
                                        <ul>
                                          <li>
                                            <label className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                Name="checkAll"
                                                checked={tempallSynopsFD}
                                                onChange={
                                                  this.handleSynopsFDAll
                                                }
                                              />
                                              <span className="checkmark" />
                                              <span className="form-check-label">
                                                {i18n.t('Select_All')}
                                              </span>
                                            </label>
                                          </li>
                                          {this.state.tempSynopsFrontSection.map(
                                            (each, index) => (
                                              <li>
                                                <label className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    key={each.ID}
                                                    Name={each.Name}
                                                    value={each.Name}
                                                    checked={each.isChecked}
                                                    onChange={() => {
                                                      this.handleSynopsFD(
                                                        index,
                                                      );
                                                    }}
                                                  />
                                                  <span className="checkmark" />
                                                  <span className="form-check-label">
                                                    {each.Name}
                                                  </span>
                                                </label>
                                              </li>
                                            ),
                                          )}
                                        </ul>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-section">
                                  <div className="form-group border-group">
                                    <label
                                      htmlFor="offering"
                                      className="form-label"
                                    >
                                      Benefit Areas
                                      {' '}
                                      <sup>*</sup>
                                    </label>
                                    <div
                                      className="custom-dropdown-search"
                                      onClick={this.isBenefitsDropdown}
                                    >
                                      <div className="custom-dropdownname">
                                        //Benefit Areas 
                                        {allBenefits ? (
                                          <span>{i18n.t('All_Selected')}</span>
                                        ) : Benefits.length != 0 ? (
                                          Benefits.length <= 2 ? (
                                            <span>
                                              {Benefits.map((each, ind) => each.Name).join(', ')}
                                              {' '}
                                            </span>
                                          ) : (
                                            <span>
                                              {Benefits.map((each, ind) => {
                                                if (ind == 0 || ind == 1) {
                                                  return (
                                                    <span>
                                                      {each.Name.length > 15
                                                        ? `${each.Name.slice(
                                                          0,
                                                          each.Name.length
                                                          - 10,
                                                        )}...`
                                                        : each.Name}
                                                      ,
                                                      {' '}
                                                    </span>
                                                  );
                                                } if (
                                                  Benefits.length > 2
                                                  && ind === Benefits.length - 1
                                                ) {
                                                  return (
                                                    <span>
                                                      +
                                                      {ind - 1}
                                                    </span>
                                                  );
                                                }
                                              })}
                                              {' '}
                                            </span>
                                          )
                                        ) : (
                                          <span>{i18n.t('Benefit Areas')}</span>
                                        )}
                                      </div>
                                    </div>
                                    {this.state.isBenefitAreaDropdownStatus ? (
                                      <div className="featuresearch-list">
                                        <ul>
                                          <li>
                                            <label className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                Name="checkAll"
                                                checked={tempallBenefits}
                                                onChange={
                                                  this.handleAllBenefitsChange
                                                }
                                              />
                                              <span className="checkmark" />
                                              <span className="form-check-label">
                                                {i18n.t('Select_All')}
                                              </span>
                                            </label>
                                          </li>
                                          {tempBenefits.map((each, index) => (
                                            <li>
                                              <label className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  key={each.ID}
                                                  Name={each.Name}
                                                  value={each.Name}
                                                  checked={each.isChecked}
                                                  onChange={() => {
                                                    this.handleBenefitsChange(
                                                      index,
                                                    );
                                                  }}
                                                />
                                                <span className="checkmark" />
                                                <span className="form-check-label">
                                                  {each.Name}
                                                </span>
                                              </label>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-section">
                                  <div className="form-group border-group">
                                    <label
                                      htmlFor="offering"
                                      className="form-label"
                                    >
                                      Beneficiaries
                                      {' '}
                                      <sup>*</sup>
                                    </label>
                                    <div
                                      className="custom-dropdown-search"
                                      onClick={this.handleBeneficiaryDropDown}
                                    >
                                      <div className="custom-dropdownname">
                                        // Beneficiaries
                                        {allBeneficiary == true ? (
                                          <span>
                                            {' '}
                                            {i18n.t('All_Selected')}
                                          </span>
                                        ) : Beneficiary.length != 0 ? (
                                          Beneficiary.length <= 2 ? (
                                            <span>
                                              {Beneficiary.map((each, ind) => each.Name).join(', ')}
                                              {' '}
                                            </span>
                                          ) : (
                                            <span>
                                              {Beneficiary.map((each, ind) => {
                                                if (ind == 0 || ind == 1) {
                                                  return (
                                                    <span>
                                                      {each.Name.length > 15
                                                        ? `${each.Name.slice(
                                                          0,
                                                          each.Name.length
                                                          - 10,
                                                        )}...`
                                                        : each.Name}
                                                      ,
                                                      {' '}
                                                    </span>
                                                  );
                                                } if (
                                                  Beneficiary.length > 2
                                                  && ind === Beneficiary.length - 1
                                                ) {
                                                  return (
                                                    <span>
                                                      +
                                                      {ind - 1}
                                                    </span>
                                                  );
                                                }
                                              })}
                                              {' '}
                                            </span>
                                          )
                                        ) : (
                                          <span>{i18n.t('Beneficiaries')}</span>
                                        )}
                                      </div>
                                    </div>
                                    {this.state
                                      .isBeneficiariesDropdownStatus ? (
                                      <div className="featuresearch-list">
                                        <ul>
                                          <li>
                                            <label className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                Name="checkAll"
                                                checked={tempallBeneficiary}
                                                onChange={
                                                  this.handleBeneficiaryAll
                                                }
                                              />
                                              <span
                                                className="checkmark"
                                                checked={tempallBeneficiary}
                                              />
                                              <span className="form-check-label">
                                                {i18n.t('Select_All')}
                                              </span>
                                            </label>
                                          </li>
                                          {tempBeneficiary.map(
                                            (each, index) => (
                                              <li>
                                                <label className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    key={each.ID}
                                                    Name={each.Name}
                                                    value={each.Name}
                                                    checked={each.isChecked}
                                                    onChange={() => {
                                                      this.handleBeneficiary(
                                                        index,
                                                      );
                                                    }}
                                                  />
                                                  <span className="checkmark" />
                                                  <span className="form-check-label">
                                                    {each.Name}
                                                  </span>
                                                </label>
                                              </li>
                                            ),
                                          )}
                                        </ul>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-newidea"
                          role="tabpanel"
                          aria-labelledby="pills-newidea-tab"
                        >
                          <h2>Rate your Experience with SynOps</h2>
                          <div className="rating-star">
                            <span className="checked" />
                            <span className="checked" />
                            <span className="checked" />
                            <span className="" />
                            <span className="" />
                          </div>
                          <div className="form-section">
                            <div className="form-group border-group">
                              <label htmlFor="comment" className="form-label">
                                Please provide suggestions or Feedback here
                                {' '}
                                <sup>*</sup>
                              </label>
                              <textarea
                                rows="3"
                                className="form-control"
                                name="text"
                              />
                            </div>
                          </div>

                          <div>
                            <label className="form-check checkbox-checked">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="check-boxes"
                              />
                              <span className="checkmark" />
                              <span className="form-check-label">
                                Click here to provide feedback anonymously
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="sucess-feedback-msg">
                    <p className="mb-2 success-icon">
                      Feedback Submitted Successfully
                    </p>
                    <p className="click-here mb-0">
                      <a onClick={() => this.handleFeedBackType('General')}>
                        Click here
                        {' '}
                      </a>
                      {' '}
                      to provide new feedback.
                    </p>
                  </div>
                )}
              </Modal.Body>

              <Modal.Footer>
                <p className="mb-0">
                  {this.state.ShowNote ? (
                    <>
                      <span>Please Note: </span>
                      {' '}
                      Your Enterprise ID will be
                      captured for this feedback and a member of our team may
                      reach out to you for additional details
                    </>
                  ) : (
                    ''
                  )}
                </p>

                {!this.state.ShowMessage && !IsFeedbackSuccess ? (
                  <Button
                    variant="primary"
                    className={
                      (this.state.ShowButton && this.state.ShowButtonRating)
                        || this.state.ShowButtonEnhancement
                        ? ''
                        : 'disabled'
                    }
                    onClick={() => this.handleSave()}
                  >
                    Submit
                  </Button>
                ) : (
                  ''
                )}
              </Modal.Footer>

              {this.state.isFrontDoorImpactDropdownStatus ? (
                <div
                  className="custom-dropdown-search-overlay"
                  onClick={this.isFrontDoorImpactDropdownOverlay}
                />
              ) : (
                ''
              )}
              {this.state.isBeneficiariesDropdownStatus ? (
                <div
                  className="custom-dropdown-search-overlay"
                  onClick={this.isBeneficiariesDropdownOverlay}
                />
              ) : (
                ''
              )}
              {this.state.isBenefitAreaDropdownStatus ? (
                <div
                  className="custom-dropdown-search-overlay"
                  onClick={this.isBenefitAreaDropdownOverlay}
                />
              ) : (
                ''
              )}
            </Modal>
          ) : null} */}
          {this.state.clientStoriesModalStatus && (
            <ClientSelectPopup
              clientStoriesModalStatus={this.state.clientStoriesModalStatus}
              clientStoriesModalClose={this.clientStoriesModalClose}
              addClientProjectShow={this.addClientProjectShow}
              editAndPublishAccess={this.editAndPublishAccess}
              isProfileExist={this.props.isProfileExist}
              FunctionalIndustryOffering={this.props.FunctionalIndustryOffering}
              userRole={this.props.userRole}
              {...this.props}
            />
          )}

          {addClientStatus && (
            <ClientBoardingPopup childToParent={this.childToParent} />
          )}
          {showMapping && (
            <AddMappingForm
              childToParent={this.childToParentForMapping}
              showMapping={this.state.showMapping}
              showDrafts={this.state.showDrafts}
              dealInfo={this.state.dealInfo}
              isClientOnboarding={true}
            />
          )}
        </LoadingOverlay>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageData: { ...state.CurrentFilter.languageData },
  roleData: state.roleReducers.roleReducers,
  notificationData: { ...state.NotificationReducer },
  userLoginData:{...state.userLoginDataReducer}
});
const mapDispatchToProps = (dispatch) => ({
  savePulseData: (data) => {
    dispatch({ type: 'PULSE_DATA', payload: data });
  },
  saveLangData: (data) => {
    dispatch({ type: 'LANGUAGE_DATA', payload: data });
  },
  saveClientData: (data) => {
    dispatch({ type: 'SELECTED_CLIENT', payload: data });
  },
  saveCommentHistoryId: (data) => {
    dispatch({ type: 'SELECTED_METRIC_COMMENT_HISTORY', payload: data });
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePage);
