import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axiosInstance from "../Shared/interceptor";
import ReactPlayer from "react-player";
import { store } from "../reducers/configureStore";
import _ from "lodash";
import moment from "moment";
import DOMPurify from 'dompurify';
import navbarLogo from "../Images/Synops-navbar-logo.svg";
import i18n from "i18next";
import { LocalApiBaseUrl, DomainBaseUrl, ProfilePic, LocalApiBaseUrlImage, PeoplePage } from "../Shared/Constant";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import "./CaseStudy.css";
import parse from 'react-html-parser';

const ExtractSrcLink = (Link) => {
  const DocsearchTerm = 'src="';
  const VideoImagesearchTerm = "?";
  const Extensions = [".mp4", ".mov", ".webm", ".mkv", ".ogv", ".3g2", ".jpeg", ".jpg", ".giff", ".png"];
  let isDoc;
  if (Extensions.some((v) => Link.toUpperCase().includes(v.toUpperCase()))) {
    isDoc = false;
    return `${Link.split(VideoImagesearchTerm)[0]}`;
  } else {
    if (Link.includes("<iframe")) {
      isDoc = true;
      return `${Link.split(DocsearchTerm)[1].split('"')[0]}`;
    } else {
      return Link;
    }
  }
};

export default class CaseStudyImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MORItemDetails: {},
      DocDetails: {},
      allData: [],
      ImageExtensions: [".jpeg", ".jpg", ".giff", ".png"],
      VideoExtensions: [".mp4", ".mov", ".webm", ".mkv", ".ogv", ".3g2"],
      minshow: false,
      isMORDoc: false,
      isMORShowDocViewer: false,
      currentIndex: null,
      isAdmin: false,
    };
  }
  handleEdit = (itemDetails) => {
    this.props.handleViewerEdit(itemDetails);
    this.onClose();
  };

  handleDelete = (itemDetails) => {
    this.props.handleViewerDelete(itemDetails);
    //this.onClose()
  };

  componentWillMount() {
    debugger;
    let { allData, DocDetails, isMORShowDocViewer, isMORDoc, ViewerItemDeatils, AllViewerItemDeatils } = this.props;
    let indexID = null;
    if (!isMORDoc) {
      allData.map((obj, index) => {
        if (obj.Id == DocDetails.Id) {
          indexID = index;
        }
      });
      this.setState({
        allData,
        isMORDoc,
        DocDetails: { ...DocDetails },
        currentIndex: indexID,
        isMORShowDocViewer,
      });
    } else {
      AllViewerItemDeatils.map((obj, index) => {
        if (obj.MORItemID == ViewerItemDeatils.ItemId) {
          indexID = index;
        }
      });
      this.setState({
        isMORDoc,
        allData: AllViewerItemDeatils,
        MORItemDetails: { ...ViewerItemDeatils },
        currentIndex: indexID,
        isMORShowDocViewer,
      });
    }
  }
  onClose = () => {
    this.setState({
      isMORShowDocViewer: false,
    });
    this.props.closeViewer();
  };
  leftCaseStudy = () => {
    const { currentIndex, DocDetails, MORItemDetails, isMORDoc, allData } = this.state;
    if (currentIndex != null) {
      if (currentIndex != 0) {
        let pos = currentIndex - 1;
        let current = allData[pos];
        if (!isMORDoc) {
          DocDetails.Id = current.Id;
          DocDetails.DocName = current.ConfigKey;
          DocDetails.DocLink = current.ConfigValue;
          DocDetails.DocDescription = current.Description;
          this.setState({
            DocDetails,
            currentIndex: pos,
          });
        } else {
          MORItemDetails.ItemId = current.MORItemID;
          MORItemDetails.ItemName = current.ItemName;
          MORItemDetails.ItemLink = current.ItemLink;
          MORItemDetails.CreatedBy = current.CreatedBy
          MORItemDetails.UpdatedTS = current.UpdatedTS
          this.setState({
            MORItemDetails,
            currentIndex: pos,
          });
        }
      }
    }
  };
  rightCaseStudy = () => {
    const { currentIndex, DocDetails, MORItemDetails, isMORDoc, allData } = this.state;
    //   const { allData } = this.props;

    if (currentIndex != null) {
      if (currentIndex != allData.length - 1) {
        let pos = currentIndex + 1;
        let current = allData[pos];
        if (!isMORDoc) {
          DocDetails.Id = current.Id;
          DocDetails.DocName = current.ConfigKey;
          DocDetails.DocLink = current.ConfigValue;
          DocDetails.DocDescription = current.Description;
          this.setState({
            DocDetails,
            currentIndex: pos,
          });
        } else {
          MORItemDetails.ItemId = current.MORItemID;
          MORItemDetails.ItemName = current.ItemName;
          MORItemDetails.ItemLink = current.ItemLink;
          MORItemDetails.CreatedBy = current.CreatedBy
          MORItemDetails.UpdatedTS = current.UpdatedTS
          this.setState({
            MORItemDetails,
            currentIndex: pos,
          });
        }
      }
    }
  };
  minimize = (e, item) => {
    const { ImageExtensions, VideoExtensions } = this.state;
    if (item !== "") {
      if (VideoExtensions.some((v) => item.includes(v))) {
        document.getElementsByTagName("ReactPlayer")[0].className = "";
      } else if (ImageExtensions.some((v) => item.toUpperCase().includes(v.toUpperCase()))) {
        document.getElementById("imageMaximize").className = "";
      } else {
        document.getElementsByTagName("iframe")[0].className = "";
      }
    } else {
      document.getElementsByTagName("iframe")[0].className = "";
    }
    this.setState({ minshow: false });
  };
  Maximize = (e, item) => {
    const { ImageExtensions, VideoExtensions } = this.state;
    if (item !== "") {
      if (VideoExtensions.some((v) => item.toUpperCase().includes(v.toUpperCase()))) {
        document.getElementsByTagName("ReactPlayer")[0].className = "fullScreen";
      } else if (ImageExtensions.some((v) => item.toUpperCase().includes(v.toUpperCase()))) {
        document.getElementById("imageMaximize").className = "fullScreen";
      } else {
        document.getElementsByTagName("iframe")[0].className = "fullScreen";
      }
    } else {
      document.getElementsByTagName("iframe")[0].className = "fullScreen";
    }
    this.setState({ minshow: true });
  };
  render() {
    const {
      DocDetails,
      MORItemDetails,
      isMORShowDocViewer,
      ImageExtensions,
      VideoExtensions,
      isMORDoc,
      isAdmin,
    } = this.state;
    const Iframe = `<iframe src=${
      !isMORDoc ? ExtractSrcLink(DocDetails.DocLink) : MORItemDetails ? ExtractSrcLink(MORItemDetails.ItemLink) : ""
    } width="100%" 
  height="500px" frameborder="0"></iframe>`;
    /* const Iframe = `<iframe src=${LocalApiBaseUrlImage}${`/SOImages/TestDoc/ppt.pptx`} width="100%" 
  height="500px" frameborder="0"></iframe>`; */
    return (
      <>
        <Modal
          show={isMORShowDocViewer}
          onHide={this.onClose}
          className="addcaseStudy-modal casestudy-detailmodal morviewer-modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className="casestudy-title">
              <img loading="lazy" src={navbarLogo} alt="Synops-logo" width="24" height="24" />{" "}
              {!isMORDoc ? DocDetails.DocName : MORItemDetails ? MORItemDetails.ItemName : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="casestudycard-content morMaximize">
              <div className="mor-maximise">
                {/* <p>{!isMORDoc?DocDetails.DocDescription:""}</p> */}
                {isMORDoc &&
                VideoExtensions.some(
                  (v) =>
                    MORItemDetails.ItemLink.toUpperCase().includes(v.toUpperCase()) 
                    //|| ImageExtensions.some((v) => MORItemDetails.ItemLink.toUpperCase().includes(v.toUpperCase()))
                ) ? (
                  ""
                ) : (
                    MORItemDetails.ItemLink !== undefined && MORItemDetails.ItemLink.toUpperCase().includes(".pdf".toUpperCase()) ?"":
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={(e) => this.Maximize(e, isMORDoc ? MORItemDetails.ItemLink : "")}
                  >
                    {i18n.t("Maximize")}
                    <i class="fas fa-compress-arrows-alt"></i>
                  </button> 
                )}
              </div>
              <div className="casestudy-modalimg">
                {this.state.minshow ? (
                  <div class="close-excel" onClick={(e) => this.minimize(e, isMORDoc ? MORItemDetails.ItemLink : "")}>
                    <i class="far fa-window-minimize"></i>
                  </div>
                ) : (
                  ""
                )}
                {isMORDoc ? (
                  VideoExtensions.some((v) => MORItemDetails.ItemLink.toUpperCase().includes(v.toUpperCase())) ? (
                    <ReactPlayer width="100%" height="500px" controls url={ExtractSrcLink(MORItemDetails.ItemLink)} />
                  ) : ImageExtensions.some((v) => MORItemDetails.ItemLink.toUpperCase().includes(v.toUpperCase())) ? (
                    <img
                      className="maxminimage"
                      src={ExtractSrcLink(MORItemDetails.ItemLink)}
                      id="imageMaximize"
                      alt="client title"
                      width="100%"
                      height="500px"
                    />
                  ) : (
                    MORItemDetails.ItemLink !== undefined &&  MORItemDetails.ItemLink.toUpperCase().includes(".pdf".toUpperCase()) ? <div className="pdflinkadded">
                    <a target="_blank" href={ExtractSrcLink(MORItemDetails.ItemLink)}>
                    Click here to view your file
                    </a>
                  </div>:
                    <div>{parse(Iframe)}</div>
                  )
                ) : (
                  <div>{parse(Iframe)}</div>
                )}
                {
                    this.state.allData.length >1 && <div className="casestudy-controllers">
                    <span className={this.state.currentIndex === 0 ?"left-controller" :"left-controller active"}  onClick={this.leftCaseStudy}>
                      <i class="fal fa-angle-left"></i>
                    </span>
                    <span className={this.state.currentIndex === (this.state.allData.length - 1) ? "right-controller" : "right-controller active"}  onClick={this.rightCaseStudy}>
                      <i class="fal fa-angle-right"></i>
                    </span>
                  </div>
                }
                
              </div>
              {isMORDoc ? (
                <div className="csupdated-info common-ellipsis-dropdown remove-transform">
                  <p>
                    <span>
                      <span className="lastupdated-on">
                      {i18n.t("Last_updated_on")}{" "}
                      {moment
                        .utc(MORItemDetails.UpdatedTS)
                        .local()
                        .format("MMMM DD, YYYY")}
                        </span>
                      &nbsp;|&nbsp;<span>{i18n.t("File_uploaded_by")}</span>{" "}
                      <span className="email-ids">{MORItemDetails.CreatedBy}@accenture.com</span>
                    </span>
                    {this.props.accessControl === "Edit" && (
                      <>
                        <div className="dropdown dropleft">
                          <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                          <div className="dropdown-menu">
                            {
                              !this.props.IsArchiveMode && 
                              <a
                              href="javascript:void(0)"
                              className="dropdown-item"
                              onClick={() => {
                                this.handleEdit(MORItemDetails);
                              }}
                            >
                              <span className="edit-icon"></span>{i18n.t("Edit")}
                            </a>
                            }
                        
                            <a
                              href="javascript:void(0)"
                              className="dropdown-item"
                              onClick={() => {
                                this.handleDelete(MORItemDetails);
                              }}
                            >
                              <span className="delete-icon"></span>{i18n.t("Delete")}
                            </a>
                          </div>
                        </div>
                      </>
                    )}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
