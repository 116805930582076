import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Tabs, Tab, Modal, Button } from "react-bootstrap";
import i18n from "i18next";
import { IoIosClose } from "react-icons/io";
import feedbackIcon from "../../Images/feedback-white.svg";
import axiosInstance from "../../Shared/interceptor";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../../Shared/SynopsLoader";
import { LocalApiBaseUrl } from "../../Shared/Constant";
import ClientDropdown from "../../RequestAccess/ClientDropdown";
import { store } from "../../reducers/configureStore";

import "./ContactUs.css";
import { set } from "lodash";

// var common = require("../../../src/Common.js");

const MAX_COUNT = 3;

const SUPPOFTED_FILE_FORMATS = [
  ".png",
  ".jpeg",
  ".docx",
  ".xlsx",
  ".csv",
  ".pptx",
  ".pdf",
  ".txt",
  ".eml",
  ".msg",
];

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const ERROR_MESSAGES = {
  FILE_SIZE_EXCEEDED: "File size exceeds 5 MB.",
  FILE_FORMAT_NOT_SUPPORTED: "File format not supported.",
  MAX_COUNT_EXCEEDED: "A maximum of 3 files can be attached.",
  DUPLICATE_FILE: "The file has already been uploaded.",
};

const TAB_NAMES = {
  FEEDBACK: "feedback",
  ISSUE: "issue",
};

const SUBMIT_SUCCESS_MESSAGE = {
  [TAB_NAMES.ISSUE]: "Issue reported successfully",
  [TAB_NAMES.FEEDBACK]: " Feedback submitted successfully",
};

const selectedClientInit = {
  client: "",
  clientID: -1,
};

const GUIDE_TEXT = "Please select Accenture, if your client is not listed in drop down";

const FEEDBACK_PLACEHOLDER_TEXT = "Provide feedback";
const ISSUE_PLACEHOLDER_TEXT = "Report an issue";

const ContactUs = (props) => {
  const [selectedTab, setSelectedTab] = useState(props.defaultTab || TAB_NAMES.FEEDBACK);
  const [allClients, setAllClients] = useState([]);
  const [selectedClientFeedback, setSelectedClientFeedback] = useState(selectedClientInit);
  const [selectedClientIssue, setSelectedClientIssue] = useState(selectedClientInit);
  const [feedbackDescription, setFeedbackDescription] = useState("");
  const [issueDescription, setIssueDescription] = useState("");
  const [uploadedFeedbackFiles, setUploadedFeedbackFiles] = useState([]);
  const [uploadedIssueFiles, setUploadedIssueFiles] = useState([]);
  const [errorFeedback, setErrorFeedback] = useState("");
  const [errorIssue, setErrorIssue] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const inputFileFeedbackRef = useRef(null);
  const inputFileIssueRef = useRef(null);

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    setShowLoader(true);
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetViewProjectsClientList`, {
        params: { isDemo: false },
      })
      .then((response) => {
        setAllClients(response.data);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const handleDropdownChangeFeedback = (client) => {
    setSelectedClientFeedback(client);
  };

  const handleDropdownChangeIssue = (client) => {
    setSelectedClientIssue(client);
  };

  const setError = (tabname, error) => {
    if (tabname === TAB_NAMES.FEEDBACK) setErrorFeedback(error);
    else setErrorIssue(error);
  };

  const handleUploadFiles = (files, tabname) => {
    let uploaded = [];
    if (tabname === TAB_NAMES.FEEDBACK) {
      uploaded = [...uploadedFeedbackFiles];
    } else {
      uploaded = [...uploadedIssueFiles];
    }

    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length > MAX_COUNT) {
          setError(tabname, ERROR_MESSAGES.MAX_COUNT_EXCEEDED);
          limitExceeded = true;
          return true;
        }
      }
      else {
        setError(tabname, ERROR_MESSAGES.DUPLICATE_FILE);
        return true;
      }
    });
    if (!limitExceeded) {
      if (tabname === TAB_NAMES.FEEDBACK) setUploadedFeedbackFiles(uploaded);
      else setUploadedIssueFiles(uploaded);
    }
  };

  const handleFileEvent = (e, tabname) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setErrorFeedback("");
    setErrorIssue("");
    let isError = false;
    chosenFiles.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.substring(fileName.lastIndexOf(".")).toLowerCase();
      if (SUPPOFTED_FILE_FORMATS.some((format) => fileExtension.endsWith(format))) {
        // File format is supported
        if (file.size <= MAX_FILE_SIZE) {
          // File size is less than or equal to 5 MB
          if (!isError) handleUploadFiles(chosenFiles, tabname);
        } else {
          // File size is greater than 5 MB
          setError(tabname, ERROR_MESSAGES.FILE_SIZE_EXCEEDED);
          isError = true;
        }
      } else {
        // File format is not supported
        setError(tabname, ERROR_MESSAGES.FILE_FORMAT_NOT_SUPPORTED);
        isError = true;
      }
    });
    e.target.value = null;
  };

  const handleDeleteFile = (file, tabname) => {
    const feedbackFiles = [...uploadedFeedbackFiles];
    const issueFiles = [...uploadedIssueFiles];
    const feedbackIndex = feedbackFiles.findIndex((f) => f.name === file.name);
    const issueIndex = issueFiles.findIndex((f) => f.name === file.name);
    if (tabname === TAB_NAMES.FEEDBACK) {
      feedbackFiles.splice(feedbackIndex, 1);
      setUploadedFeedbackFiles(feedbackFiles);
    } else if (tabname === TAB_NAMES.ISSUE) {
      issueFiles.splice(issueIndex, 1);
      setUploadedIssueFiles(issueFiles);
    }
  };

  const handleTabChange = (key) => {
    if (key === "RequestDealAccess") {
      props.history.push(`/RequestAccess?from=ContactUs&tab=${selectedTab}`);
    } else {
      setSelectedTab(key);
    }
  };

  const clearAllStates = () => {
    setSelectedClientFeedback(selectedClientInit);
    setSelectedClientIssue(selectedClientInit);
    setFeedbackDescription("");
    setIssueDescription("");
    setUploadedFeedbackFiles([]);
    setUploadedIssueFiles([]);
    setSelectedTab(TAB_NAMES.FEEDBACK);
    setErrorFeedback("");
    setErrorIssue("");
    setSuccessMessage("");
  };

  let isSubmitDisabled = true;

  if (selectedTab === TAB_NAMES.FEEDBACK) {
    isSubmitDisabled = !selectedClientFeedback.OrgEntityID || !feedbackDescription;
  } else if (selectedTab === TAB_NAMES.ISSUE) {
    isSubmitDisabled = !selectedClientIssue.OrgEntityID || !issueDescription;
  }

  const handleSubmit = () => {
    const formData = new FormData();
    const languageid = store.getState().CurrentFilter.languageData.id;
    const requestData = {
      FeedbackType: selectedTab,
      LanguageID: languageid,
      Title: "0",
      TitleDescription: selectedTab === TAB_NAMES.ISSUE ? issueDescription : feedbackDescription,
      BenefitIds: "0",
      BeneficiaryIds: "0",
      SynopsFuncSectionIds: "0",
      Rating: 0,
      Feedback: selectedTab === TAB_NAMES.FEEDBACK ? feedbackDescription : issueDescription,
      isAnonymous: false,
      ClientName:
        selectedTab === TAB_NAMES.FEEDBACK
          ? selectedClientFeedback.OrgEntityName
          : selectedClientIssue.OrgEntityName,
      MailSubject: selectedTab === TAB_NAMES.FEEDBACK ? "Feedback" : "Issue",
      MailBody: selectedTab === TAB_NAMES.FEEDBACK ? feedbackDescription : issueDescription,
    };

    for (const key in requestData) {
      formData.append(key, requestData[key]);
    }

    if (selectedTab === TAB_NAMES.FEEDBACK) {
      uploadedFeedbackFiles.forEach((file) => {
        formData.append("MultiFiles", file);
      });
    } else if (selectedTab === TAB_NAMES.ISSUE) {
      uploadedIssueFiles.forEach((file) => {
        formData.append("MultiFiles", file);
      });
    }

    setShowLoader(true);
    axiosInstance
      .post(`${LocalApiBaseUrl}UserGuide/SubmitFeedbackFormWithAttachments`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setTimeout(() => {
          props.closeContactSupportModal();
        clearAllStates();
        }, 2000);
        setSuccessMessage(SUBMIT_SUCCESS_MESSAGE[selectedTab]);
        
      })
      .catch((error) => {
        window.alert(error);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  return (
    <div>
      <Modal
        show={props.open}
        onHide={() => {
          props.closeContactSupportModal();
          clearAllStates();
        }}
        className="feedbackFormModal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div className="common-tablayout">
            <Tabs activeKey={selectedTab} id="uncontrolled-tab-example" onSelect={handleTabChange}>
              <Tab eventKey={TAB_NAMES.FEEDBACK} title={i18n.t("Provide Feedback")}>
                <div className="d-flex">
                  <ClientDropdown
                    selectedClients={selectedClientFeedback}
                    handleDropdownChange={handleDropdownChangeFeedback}
                    allClients={allClients}
                  />
                  <div className="support-text">{GUIDE_TEXT}</div>
                </div>

                <div className="form-section">
                  <div className="form-group border-group">
                   
                    <textarea
                      rows="5"
                      className="form-control"
                      name="text"
                      placeholder={FEEDBACK_PLACEHOLDER_TEXT + "*"}
                      value={feedbackDescription}
                      onChange={(e) => setFeedbackDescription(e.target.value)}
                      maxLength={500}
                    />
                  </div>
                </div>
                <input
                  id="fileUpload"
                  type="file"
                  multiple
                  onChange={(e) => handleFileEvent(e, TAB_NAMES.FEEDBACK)}
                  hidden
                  ref={inputFileFeedbackRef}
                />
                <div
                  width="485px"
                  height="50px"
                  display="flex"
                  className="Grid__StyledDiv-sc-9k217a-0 hAXbWy mt-3"
                >
                  <div display="flex" className="Grid__StyledDiv-sc-9k217a-0 cFOIUY">
                    <i
                      className="fas fa-plus-circle cursor css-fbkt3g"
                      onClick={() => inputFileFeedbackRef.current.click()}
                    />

                    <div className="Grid__StyledDiv-sc-9k217a-0 la-dTuR">
                      <div font-size="12px" className="Grid__StyledDiv-sc-9k217a-0 dAhoyD">
                        Add attachments
                      </div>
                      <div
                        font-size="10px"
                        color="#919EAB"
                        className="Grid__StyledDiv-sc-9k217a-0 kbxoxF"
                      >
                        3 attachments upto 5 MB are permitted
                      </div>
                      <div
                        font-size="10px"
                        color="#919EAB"
                        className="Grid__StyledDiv-sc-9k217a-0 kbxoxF"
                      >
                        Supported file formats:&nbsp;
                        {SUPPOFTED_FILE_FORMATS.join(", ")}
                      </div>
                    </div>
                  </div>
                  <div display="flex" className="Grid__StyledDiv-sc-9k217a-0 bRDpfN">
                    {uploadedFeedbackFiles.map((file) => (
                      <div class="Grid__StyledDiv-sc-9k217a-0 cGODdJ">
                        <div display="flex" class="Grid__StyledDiv-sc-9k217a-0 fJlQtE">
                          <span class="sc-fWCJzd jzuRnk">{file.name}</span>

                          <IoIosClose
                            className="cursor"
                            onClick={() => handleDeleteFile(file, TAB_NAMES.FEEDBACK)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="contact-us-error">{errorFeedback}</div>
              </Tab>
              <Tab eventKey={TAB_NAMES.ISSUE} title={i18n.t("Report an Issue")}>
                <div className="d-flex">
                  <ClientDropdown
                    selectedClients={selectedClientIssue}
                    handleDropdownChange={handleDropdownChangeIssue}
                    allClients={allClients}
                  />
                  <div className="support-text">{GUIDE_TEXT}</div>
                </div>

                <div className="form-section">
                  <div className="form-group border-group">
                    <textarea
                      rows="5"
                      className="form-control"
                      name="text"
                      placeholder={ISSUE_PLACEHOLDER_TEXT + "*"}
                      value={issueDescription}
                      onChange={(e) => setIssueDescription(e.target.value)}
                      maxLength={500}
                    />
                  </div>
                </div>
                <input
                  id="fileUpload"
                  type="file"
                  multiple
                  onChange={(e) => handleFileEvent(e, TAB_NAMES.ISSUE)}
                  hidden
                  ref={inputFileIssueRef}
                />
                <div
                  width="485px"
                  height="50px"
                  display="flex"
                  className="Grid__StyledDiv-sc-9k217a-0 hAXbWy mt-3"
                >
                  <div display="flex" className="Grid__StyledDiv-sc-9k217a-0 cFOIUY">
                    <i
                      className="fas fa-plus-circle cursor css-fbkt3g"
                      onClick={() => inputFileIssueRef.current.click()}
                    />

                    <div className="Grid__StyledDiv-sc-9k217a-0 la-dTuR">
                      <div font-size="12px" className="Grid__StyledDiv-sc-9k217a-0 dAhoyD">
                        Add attachments
                      </div>
                      <div
                        font-size="10px"
                        color="#919EAB"
                        className="Grid__StyledDiv-sc-9k217a-0 kbxoxF"
                      >
                        3 attachments upto 5 MB are permitted
                      </div>
                      <div
                        font-size="10px"
                        color="#919EAB"
                        className="Grid__StyledDiv-sc-9k217a-0 kbxoxF"
                      >
                        Supported file formats:&nbsp;
                        {SUPPOFTED_FILE_FORMATS.join(", ")}
                      </div>
                    </div>
                  </div>
                  <div display="flex" className="Grid__StyledDiv-sc-9k217a-0 bRDpfN">
                    {uploadedIssueFiles.map((file) => (
                      <div class="Grid__StyledDiv-sc-9k217a-0 cGODdJ">
                        <div display="flex" class="Grid__StyledDiv-sc-9k217a-0 fJlQtE">
                          <span class="sc-fWCJzd jzuRnk">{file.name}</span>

                          <IoIosClose
                            className="cursor"
                            onClick={() => handleDeleteFile(file, TAB_NAMES.ISSUE)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="contact-us-error">{errorIssue}</div>
              </Tab>
              <Tab eventKey="RequestDealAccess" title={i18n.t("Request Access")} />
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p class="mb-0 success-message">{successMessage ? successMessage : ""}</p>
          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitDisabled}>
            {i18n.t("Submit")}
          </Button>
        </Modal.Footer>
      </Modal>
      {showLoader ? <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active /> : ""}
    </div>
  );
};

export default withRouter(ContactUs);
